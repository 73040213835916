body {
  font-family:  "Arial", sans-serif;
  font-size: $font-size-base;
  background-color: $bg-color;
  padding: 0;
  margin: 0;
}


hr {
  background: none;
  border-top: 1px solid $input-border-color;
  border-bottom: none;
  margin-top: 15px;
  margin-bottom: 15px;
}