@mixin appearance($args) {
  -moz-appearance: $args;
  -webkit-appearance: $args;
  appearance: $args;
}
@mixin box-sizing($args) {
  -webkit-box-sizing: $args;
  -moz-box-sizing: $args;
  box-sizing: $args;
}

@mixin box-shadow($args) {
  -webkit-box-shadow: $args;
  -moz-box-shadow: $args;
  box-shadow: $args;
}