input[readonly],
input[disabled] {
  background-color: $disabled-color;
  color: $disabled-text-color;
}

.react-select--is-disabled {
  background-color: $disabled-color;
}

.react-select__single-value.react-select__single-value--is-disabled {
  color: $disabled-text-color;
}
.form-group {
  margin: 10px 0;

  label, legend {
    margin: 5px 0 0 0;
    color: $label-color;

  }

  select.form-input::-ms-expand {
    display: none   ;
  }

  select.form-input {
    @include appearance(none);
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iYXJyb3ctZG93biIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNzAgNzAiPiAgPGRlZnM+ICAgIDxzdHlsZT4gICAgICAuY2xzLTEgeyAgICAgICAgZmlsbDogbm9uZTsgICAgICAgIHN0cm9rZTogIzExMjQyODsgICAgICAgIHN0cm9rZS1saW5lY2FwOiByb3VuZDsgICAgICAgIHN0cm9rZS1saW5lam9pbjogcm91bmQ7ICAgICAgICBzdHJva2Utd2lkdGg6IDJweDsgICAgICB9ICAgIDwvc3R5bGU+ICA8L2RlZnM+ICA8dGl0bGU+bXotYXJyb3ctZG93bjwvdGl0bGU+ICA8cG9seWxpbmUgY2xhc3M9ImNscy0xIiBwb2ludHM9IjU5IDIzIDM1IDQ3IDExIDIzIi8+PC9zdmc+);

    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position: calc(100% - 5px) center;
    -webkit-appearance: none;
  }

  .form-input {
    @include box-sizing(border-box);
    border-radius: 3px;
    border: 1px solid $input-border-color;
    font-size: $font-size-base;
    width: 100%;
    padding:  $input-padding-y $input-padding-x;
  }

  .form-input-invalid {
    border-color: $red;
    //color: $red;
  }

  .label-invalid {
    color: $red;
    margin-bottom: 3px;
  }
}

.legend {
  font-weight: 500;
  font-size: $font-size-lg;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

textarea {
  font-family: "Arial", sans-serif;
  height: 100px;

}

.cb-group {
  padding: 5px 0;

  label + label  {
    margin-right: 5px;
    margin-left: 10px;
  }
}

.required:after {
  content: " *";
  color: $red;
}