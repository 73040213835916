$gutter: 8px;

.row {
  margin-left: -$gutter;

}

%col {
  margin-left: $gutter;
}

@for $i from 1 through 12 {
  .col-#{$i} {
    @extend %col;
    width: calc(#{$i} / 12 * 100% - #{$gutter});
    display: inline-block;
    
  }
}

@media (min-width: 768px) {
  @for $i from 1 through 12 {
    .col-md-#{$i} {
      width: calc(#{$i} / 12 * 100% - #{$gutter});
      display: inline-block;
    }
  }
}


.header {
  background-color:$white;
  //padding: 20px 0;
}

.menu {
  @include box-shadow(5px 5px 15px $light-grey);
  background-color: $red;
  height: 30px;
  margin-bottom: 20px;
}

.wrapper {
  max-width: 680px;
}

.float-right {
  float: right;
}