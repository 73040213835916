.btn {
  border: none;
  border-radius: 3px;
  //color: #f0f0f0;
  background-color: #e5e5e5;
  font-size: $font-size-base;
  padding: 5px 15px;
  & + .btn {
    margin-left: 5px;
  }
}

.btn-primary {
  background-color: #226fbe;
  color: white;

  &:disabled {
    background-color: rgba(#226fbe, 0.5);
    color: rgba(white, 0.5);

  }
}

.btn-secondary {
  background-color: $light-grey;
  color: white;
}

.btn-lg {
  padding: 10px 20px;
}