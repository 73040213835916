$spacing-lg: 30px;
$spacing-md: 10px;
$input-padding-x: 10px;
$input-padding-y: 5px;


@for $i from 1 through 5 {
  .ml-#{$i * 5} {
    margin-left: 0px + $i * 5;
  }

  .mr-#{$i * 5} {
    margin-right: 0px + $i * 5;
  }
}