.react-select__control {
  border-color: $input-border-color !important;
  min-height: 0!important;
}

.react-select__value-container {
  padding:  $input-padding-y $input-padding-x !important;

  >div {
    margin: 0!important;
  }
}

.react-select__single-value {
  margin: 0 !important;
}
.react-select__indicator {
  padding: 0 8px !important;
}