$font-size-base: 12px;
$font-size-lg: $font-size-base * 1.3;

.right-aligned {
  text-align: right;
}

@media (min-width: 768px) {
  .right-aligned-md {
    text-align: right;
  }
}

.top-aligned {
  vertical-align: top;
  label {
    display: inline-block;
  }

}