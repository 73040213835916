.modal-overlay {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.modal-content {
  padding: 0 0 $spacing-md 0;
}

.modal-wrapper {
  @include box-shadow(0 0 25px -7px rgba(0, 0, 0, 0.2));
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  max-width: 500px;

  background-color: $bg-color;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: $spacing-lg;
  outline: none;
  font-size: $font-size-lg;
  &:focus {
    @include box-shadow(none);
    border: none;
  }
}